import { brandName } from '@brand/config/brand-config'
import { ReactComponent as BackIcon } from '@brand/icons/back.svg'
import { NavLinks } from '@brand/slots/nav-links/nav-links'
import { Logo } from '@brand/slots/logo/logo'
import clsx from 'clsx'
import type { MouseEventHandler, ReactNode } from 'react'
import { forwardRef } from 'react'
import { SkipLink } from '../../../../components/skip-link/skip-link'
import type { PageHeader_QueryFragment } from '../../../../features/page-header/__generated__/page-header.gql'
import { DrawerMenu } from '@brand/slots/page-header/drawer-menu/drawer-menu'
import type { DrawerMenuLinks } from '../../../../features/page-header//drawer-menu/drawer-menu.types'
import { PageHeaderAuthTriggers } from './page-header-auth-triggers'

import { PageHeaderUserMenu } from '../../../../features/page-header/page-header-user-menu'
import styles from './page-header.module.css'

export type PageHeaderOwnProps = {
  isFixed?: boolean
  /** Whether to show the back button on screens <= 1024px */
  showBackButton?: boolean
  mobileContent?: ReactNode
  marketTrendsUrl?: string | null
  seoLinks?: DrawerMenuLinks
  onBackClick?: MouseEventHandler<HTMLButtonElement>
  theme?: {
    pageHeaderClassname?: string
    pageHeaderBackButtonClassname?: string
  }
}

export type PageHeaderProps = PageHeaderOwnProps & PageHeader_QueryFragment

const possibleSkipLinkTargetSelector = ['a', 'button', 'input', 'textarea']
  .map((str) => `main ${str}`)
  .join(',')

export const PageHeader = forwardRef<HTMLDivElement, PageHeaderProps>(
  function PageHeader(props: PageHeaderProps, ref) {
    return (
      <header
        className={clsx(
          styles.pageHeader,
          props.isFixed && styles.pageHeaderFixed,
          props.showBackButton && styles.pageHeaderWithBackButton,
          props.theme?.pageHeaderClassname
        )}
        data-tid="header"
        data-tag_section="header"
        ref={ref}
      >
        <div className={styles.pageHeaderLeft}>
          <SkipLink
            className={styles.pageHeaderSkipLink}
            selector={possibleSkipLinkTargetSelector}
            id=""
          />

          {props.showBackButton && (
            <button
              className={clsx(
                styles.pageHeaderBack,
                props.theme?.pageHeaderBackButtonClassname
              )}
              aria-label="Back to search result"
              onClick={props.onBackClick}
              data-tag_item="back-button-arrow"
            >
              <BackIcon aria-hidden data-tid="back-button-arrow" />
            </button>
          )}

          <DrawerMenu
            location={props.location}
            marketTrendsUrl={props.marketTrendsUrl}
          />

          <a
            href="/"
            data-tid="logo"
            className={styles.pageHeaderLogoLink}
            aria-label={brandName}
            data-tag_item="rent_logo"
          >
            <Logo className={styles.pageHeaderLogo} />
          </a>
        </div>

        {props.mobileContent && (
          <div className={styles.pageHeaderMobileContent}>
            {props.mobileContent}
          </div>
        )}

        <nav data-tid="header-nav">
          <ul
            className={clsx(styles.pageHeaderLinks, {
              [styles.pageHeaderLinksHiddenOnMobile]:
                props.mobileContent || props.showBackButton,
            })}
          >
            <NavLinks
              mobileContent={props.mobileContent}
              location={props.location}
              marketTrendsUrl={props?.marketTrendsUrl}
            />

            {!props.mobileContent ? <PageHeaderAuthTriggers /> : null}
          </ul>
        </nav>

        {!props.mobileContent ? <PageHeaderUserMenu /> : null}
      </header>
    )
  }
)
